<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="20">
        <el-button type="primary"
                   @click="toAdd">
          新增
        </el-button>
        <el-button type="primary"
                   v-if="is_research"
                   @click="assign">
          预组卷
        </el-button>
        <el-button type="primary"
                   @click="toZhiding">
          指定学生
        </el-button>
      </el-col>
    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
    <addForm ref="addFormRef" />
    <EditForm ref="EditFormRef" />
    <Assign ref="assignRef" />
  </div>
</template>

<script>
import addForm from './components/index.vue'
import EditForm from './components/editForm.vue'
import Assign from './components/assign.vue'
import { sheetUrl } from '@/utils/basicUrl.js'
import { getSSOUrl } from '@/api/gd.js'

export default {
  data () {
    return {
      tableData: [],
      page: 1,
      is_research: Number(window.localStorage.getItem('is_research')),
      size: 10,
      totalElements: 0,
      showList: [],
      pageName: 'cz_template_admin',
      tableColumns: [
        { prop: 'edu_paper_id', align: 'center', label: 'ID', width: '', showToopic: false },
        { prop: 'paper_name', align: 'center', label: '试卷名称', width: '', showToopic: true },
        { prop: 'subject_name', align: 'center', label: '科目名称', width: '', showToopic: false },
        { prop: 'district_name', align: 'center', label: '地区', width: '', showToopic: false },
        { prop: 'paper_time', align: 'center', label: '开始时间', width: '200', showToopic: false, sortable: true },
        { prop: 'end_time', align: 'center', label: '截止时间', width: '200', showToopic: false, sortable: true },
        { prop: 'paper_score', align: 'center', label: '总分', width: '', showToopic: false },

        // { prop: 'gradeORclass', align: 'center', label: '年级或班级', width: '180', showToopic: false },
        { prop: 'paper_enable', align: 'center', label: '是否提交', width: '', showToopic: false, isStatus: true },
        { prop: 'is_test_paper', align: 'center', label: '是否测试试卷', width: '', showToopic: false, isStatus: true },
        { prop: 'sheet_status', prop1: 'sheet_status2', align: 'center', label: '答题卡状态', width: '', showToopic: false, isStatus: true, twoRow: true },
        // { prop: 'is_test_paper', align: 'center', label: '是否测试试卷', width: '', showToopic: false, isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', type: 'img', width: '350', btns: [
            { content: '修改', type: 'edit', event: 'editRow', isHide: false },
            { content: '查看', type: 'look', event: 'toInfo' },
            { content: '制作答题卡', type: 'export', event: 'exportCard' },
            { content: '面批', event: 'toCorrectPaper', isBtnStatus: true },
            { content: '收藏', event: 'collect', type: "collect", isChangeType: true },
            { content: '删除', type: 'del', event: 'delRow' },
            { content: '考试准备', type: 'exam', event: 'jumpGd', isBtnStatus: false, isPd: true },
            { content: '阅卷', type: 'preview', event: 'jumpGd2', isBtnStatus: false, isPd: true },
          ]
        },
      ]
    }
  },
  components: {
    addForm, EditForm, Assign
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    getBtnStatus (opItem, row) {
      if (opItem.event.indexOf("jumpGd") != -1) {
        let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
        if (Number(userInfo['is_admin']) == 1 || [416, 417, 1].find(value => value === Number(userInfo['edu_school_id']))) {
          return true
        } else {
          return false
        }
      }
    },
    getClass (prop, value) {
      return value ? 'pass' : 'notpass'
    },
    getValue (prop, value) {
      if (prop == 'paper_enable') {
        return value ? '已提交' : '未提交'
      }
      if (prop == 'is_test_paper') {
        return value ? '是' : '否'
      }
      if (prop == 'sheet_status' || prop == 'sheet_status2') {
        //"关联答题卡数据状态"  0 未关联  -1 取消申请 1 制作中  2 制作完成，未确定 3 已生成 4 申请中",
        switch (value) {
          case 0: return "未关联";
          case -1: return "取消申请";
          case 1: return "制作中";
          case 2: return "制作完成，未确定";
          case 3: return "已生成";
          case 4: return "申请中";
          default: break;
        }
      }
    },
    jumpGd () {
      getSSOUrl('sso').then(res => {
        if (res.data) {
          window.open(res.data.url, '_blank')
        }
      })
    },
    jumpGd2 () {
      getSSOUrl('review').then(res => {
        if (res.data) {
          window.open(res.data.url, '_blank')
        }
      })
    },
    getIconContent (opItem, row) {
      if (opItem.type == "collect") {
        if (row['is_collect'] == 0) {
          return '收藏'
        } else {
          return '取消收藏'
        }
      }
    },
    getIconType (opItem, row) {
      if (opItem.type == "collect") {
        if (row['is_collect'] == 0) {
          return 'collect'
        } else {
          return 'cancelCollect'
        }
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/combination/del_edu_paper',
          method: 'post',
          data: {
            edu_paper_id: row.edu_paper_id
          }
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.refresh()
        })
      })
    },
    toAdd () {
      let form = {
        paper_name: '',
        paper_time: '',
        end_time: '',
        given_class: '',
        subjectId: '',
        paper_district_id: '',
      }
      this.$refs.addFormRef.form = form
      this.$refs.addFormRef.getList()
      this.$refs.addFormRef.dialogVisible = true
    },
    initData (page, limit) {
      this.$http({
        url: '/api/v1/combination/rule_index',
        method: 'get',
        params: {
          page,
          limit,
          module: 6 //模板组卷
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    toInfo (row) {
      this.$router.push('/testPapercomposition/templatePaper/addDetails?edu_paper_id=' + row.edu_paper_id)
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    setList (list) {
      this.showList = list
    },
    collect (row) {
      this.$http({
        url: '/api/v1/combination/collect',
        method: 'post',
        data: {
          edu_paper_id: row.edu_paper_id
        }
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.refresh()
      })
    },
    editRow (row) {
      this.$http({
        url: '/api/v1/combination/getPaperInfoAndRelation',
        method: 'get',
        params: {
          id: row.edu_paper_id
        }
      }).then(res => {
        // this.$refs.EditFormRef.getPaperTypeList(res.data.info)
        this.$refs.EditFormRef.initForm(res.data.info)
        this.$refs.EditFormRef.dialogVisible = true
      })
    },
    toZhiding () {
      this.$router.push('/testPapercomposition/templatePaper/assignStu')
    },
    assign () {
      let form = {
        paper_name: '',
        paper_time: '',
        end_time: '',
        given_class: '',
        subjectId: '',
        paper_district_id: '',
        permissions: 1
      }
      this.$refs.assignRef.form = form
      this.$refs.assignRef.getList()
      this.$refs.assignRef.dialogVisible = true
    },
    exportCard (row) {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      window.open(`${sheetUrl}/#/?czb=1&paper_id=${row.edu_paper_id}&cannot_edit=1&t=${userInfo.token}&rt=${userInfo.refresh_token}`, '_blank')
    },
    toCorrectPaper (row) {
      this.$router.push('/correctPaperCard?edu_paper_id=' + row.edu_paper_id)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>